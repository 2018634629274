//BRAND
export const name = 'AI CourseCraft.io';
export const company = 'Fusion Studio Design SRL';
export const websiteURL = 'https://coursecraft.io';
export const serverURL = 'https://coursecraft-usa.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/coursecraft-3193a.appspot.com/o/logo.png?alt=media&token=b9679b21-e68a-40ad-8719-f8ceb5123205';
export const razorpayEnabled = false;
export const paypalEnabled = true;
export const stripeEnabled = true;
export const paystackEnabled = false;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 4;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 40;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AI Course Craft has completely transformed how I create content, effortlessly delivering precise and pertinent topics. It's an absolute game-changer, streamlining my workflow and elevating the caliber and relevance of my courses. For any educator aiming for efficiency and impactful online learning, this tool is indispensable.";
export const from = "David Thompson";
export const profession = 'Software Engineer - DataSphere Innovations';
export const photoURL = 'https://play-lh.googleusercontent.com/sV_ffBmBJt_je4RZHnfaCfcnL-Hy6C14Iol7H5EMj9fzI2GDOonuojdn5t9p6n9IAX8j';

//PAYPAL
export const paypalPlanIdOne = "P-5PU32921LT151325HMYVM6SY";
export const paypalPlanIdTwo = "P-4S951025M75218803MYVM66I";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";

//STRIPE
export const stripePlanIdOne = "price_1PprayLyYSDoLFFd1vEVLWkN";
export const stripePlanIdTwo = "evt_1PprbuLyYSDoLFFdHBcJGtwM";

//PAYSTACK
export const paystackPlanIdOne = "PLN_ouqmm8eo6i2k9k8";
export const paystackPlanIdTwo = "PLN_1v1xqb8io9t5lis";
export const amountInZarOne = '170';
export const amountInZarTwo = '1871';